import "core-js/modules/es6.regexp.split";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es6.regexp.replace";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Head from '@/components/head/index';
import StaffSelect from '@/components/StaffSelect/StaffSelectone';
import TimeScreeningse from '@/components/timeScreeningse';
import nxDataTabs from '@/components/nx-data-tabs/nx-data-tabs';
import Grading from '@/components/Grading';
import MyExportExcel from '@/components/MyExportExcel/common';
import { getPromotionStructure, getUserScoreList } from '@/api/gradeRules';
export default {
  name: 'outbound',
  data: function data() {
    var _this = this;
    return {
      // 系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      // 系统教程
      title: '人员定级数据',
      mailListVisibleEs: false,
      popup: {
        listLoading: false,
        cols: [{
          label: '部门名称',
          prop: 'name',
          width: "170"
        }, {
          label: '定级周期',
          prop: 'rating_cycle',
          render: function render(h, params) {
            var cycle = Number(params.row.rating_cycle);
            var cycleText = '';
            if (cycle == 1) {
              cycleText = '周';
            } else if (cycle == 2) {
              cycleText = '月';
            } else if (cycle == 3) {
              cycleText = '季度';
            } else if (cycle == 4) {
              cycleText = '年度';
            }
            return h('span', cycleText);
          }
        }, {
          label: '总成交量（单）',
          prop: 'order_num_of_cycle'
        }, {
          label: '成交得分',
          prop: 'order_score'
        }, {
          label: '3个月内销转率',
          prop: 'quarter_sales_conversion',
          render: function render(h, params) {
            // 0-1 
            var num = Number(params.row.quarter_sales_conversion);
            if (num == 0) {
              return h('span', {}, 0);
            }
            if (num > 0 && num <= 1) {
              return h('span', _this.accMul(num, 100) + '%');
            }
          }
        }, {
          label: '销转率得分',
          prop: 'sales_conversion_score'
        }, {
          label: '平均有效通话时长(分钟)',
          prop: 'avg_call_time',
          render: function render(h, params) {
            // s转分
            if (Number(params.row.avg_call_time) > 0) {
              var min = (Number(params.row.avg_call_time) / 60).toFixed(2);
              return h('span', "".concat(min));
            }
          }
        }, {
          label: '有效通话时长(分钟)',
          prop: 'call_time_of_cycle',
          render: function render(h, params) {
            // s转分
            if (Number(params.row.call_time_of_cycle) > 0) {
              var min = (Number(params.row.call_time_of_cycle) / 60).toFixed(2);
              return h('span', "".concat(min));
            }
          }
        }, {
          label: '通话时长得分',
          prop: 'avg_call_time_score'
        },
        // { label: '平均跟进时效(总)', prop: 'avg_follow_aging' },
        // { label: '个人平均跟进时效', prop: 'first_call_interval_time' },
        {
          label: '平均跟进时效(总)',
          prop: 'avg_follow_aging',
          width: "150",
          render: function render(h, params) {
            var num = Number(params.row.avg_follow_aging);
            if (num > 0) {
              return h('span', _this.gettime(num));
            }
          }
        }, {
          label: '个人平均跟进时效',
          prop: 'first_call_interval_time',
          width: "150",
          render: function render(h, params) {
            var num = Number(params.row.first_call_interval_time);
            if (num > 0) {
              return h('span', _this.gettime(num));
            }
          }
        }, {
          label: '跟进时效得分',
          prop: 'follow_aging_score'
        }, {
          label: '总得分',
          prop: 'total_score'
        }, {
          label: '人员级别',
          prop: 'user_level',
          render: function render(h, params) {
            if (params.row.user_level) {
              var level = Number(params.row.user_level);
              var levyext = '';
              if (level == 1) {
                levyext = 'A';
              } else if (level == 2) {
                levyext = 'B';
              } else if (level == 3) {
                levyext = 'C';
              }
              // user_is_new 1新人0否
              if (levyext == 'B' && params.row.user_is_new == '1') {
                return h('div', {
                  style: {
                    position: 'relative'
                  }
                }, [h('span', levyext), h('span', {
                  class: 'new-person-icon'
                }, '新人')]);
              } else {
                return h('span', levyext);
              }
            }
          }
        }, {
          label: '人员排名',
          prop: 'level_ranking'
        }]
      },
      value: '',
      options: [],
      tableData: [],
      // formInline: {},
      formInline: {
        structure_id: '',
        user_name: '',
        date_time: '',
        type: 2
      }
    };
  },
  mounted: function mounted() {
    if (this.course != 1) {} else {}
    this.selectDataFun();
    this.queryClick();
  },
  created: function created() {
    this.formInline.date_time = this.defaultyime;
  },
  components: {
    Head: Head,
    TimeScreeningse: TimeScreeningse,
    nxDataTabs: nxDataTabs,
    StaffSelect: StaffSelect,
    Grading: Grading,
    MyExportExcel: MyExportExcel
  },
  computed: {
    defaultyime: function defaultyime() {
      var date = new Date();
      var seperator1 = "-";
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentdate = year + seperator1 + month + seperator1 + strDate;
      return currentdate;
    }
  },
  methods: {
    downLoadExcelFun: function downLoadExcelFun() {
      var url = 'AutoAssignRankUser/userScoreListExport';
      var data = {
        type: 2,
        structure_id: this.formInline.structure_id,
        user_name: this.formInline.user_name
      };
      var flag = true;
      if (this.formInline.date_time) {
        data.date_time = this.formInline.date_time.replace(/-/g, '');
      } else {
        flag = false;
        this.$message('请选择日期!');
      }
      var currentTime = this.currentTime();
      var title = "".concat(currentTime, "_\u5B9A\u7EA7\u4EBA\u5458\u4FE1\u606F.xlsx");
      if (flag) {
        this.$refs.downloadExcelRef.propDataFun(url, data, title);
      }
    },
    // 百分比换算
    accMul: function accMul(arg1, arg2) {
      var r1 = arg1.toString();
      var r2 = arg2.toString();
      var m = 0;
      try {
        m += arg1.toString().split('.')[1].length;
      } catch (e) {}
      try {
        m += arg2.toString().split('.')[1].length;
      } catch (e) {}
      return Number(r1.replace('.', '')) * Number(r2.replace('.', '')) / Math.pow(10, m);
    },
    queryClick: function queryClick() {
      var _this2 = this;
      //列表数据
      var params = {
        structure_id: this.formInline.structure_id,
        user_name: this.formInline.user_name,
        type: 2
      };
      if (this.formInline.date_time) {
        params.date_time = this.formInline.date_time.replace(/-/g, '');
      }
      getUserScoreList(params).then(function (respomse) {
        _this2.tableData = respomse.data;
      });
    },
    // 所属部门下拉数据
    selectDataFun: function selectDataFun() {
      var _this3 = this;
      getPromotionStructure().then(function (respomse) {
        _this3.options = respomse.data;
      });
    },
    gettime: function gettime(val) {
      if (val == 0) {
        return '-';
      }
      if (!val) {
        return '';
      }
      var absVal = Math.abs(val);
      var str = '';
      if (Number(absVal) >= 24 * 60 * 60) {
        var day = parseInt(Number(absVal) / (24 * 60 * 60));
        var yu = Number(absVal) % (24 * 60 * 60);
        var hour = parseInt(yu / (60 * 60));
        var min = parseInt(yu % (60 * 60) / 60);
        var sec = parseInt(yu % (60 * 60) % 60);
        str = "".concat(day, "\u5929").concat(hour, "\u65F6").concat(min, "\u5206").concat(sec, "\u79D2");
      } else if (Number(absVal) >= 60 * 60) {
        var _hour = parseInt(Number(absVal) / (60 * 60));
        var _min = parseInt(Number(absVal) % (60 * 60) / 60);
        var _sec = parseInt(Number(absVal) % (60 * 60) % 60);
        str = "".concat(_hour, "\u65F6").concat(_min, "\u5206").concat(_sec, "\u79D2");
      } else if (Number(absVal) >= 60) {
        var _min2 = parseInt(Number(absVal) / 60);
        var _sec2 = parseInt(Number(absVal) % 60);
        str = "".concat(_min2, "\u5206").concat(_sec2, "\u79D2");
      } else if (Number(absVal) < 60) {
        str = "".concat(Number(absVal), "\u79D2");
      }
      if (Number(val) < 0) {
        return "-".concat(str);
      }
      return "".concat(str);
    }
  }
};